import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { RefetchPlatformData } from '../refetchPlatformData/refetchPlatformData';
import { FilterTypes } from '../../ViewModel/filterViewModel/filterViewModel';
import { TriggeredByOptions } from '../../../../types/types';

export type OnFilterChanged = (
  filter: Partial<record<filtertypes, string[]="">> | | undefined,
) => Promise<void>;

export function createOnFilterChanged(
  {
    getControllerState,
    context: { biLogger, experiments },
  }: ActionFactoryParams<calendarstate, CalendarContext="">,
  refetchPlatformData: RefetchPlatformData,
): OnFilterChanged {
  return (filter: Parameters<onfilterchanged>[0]) => {
    const [state, setState] = getControllerState();

    setState({
      filterOptions: filter
        ? {
            ...state.filterOptions,
            ...filter,
          }
        : {
            [FilterTypes.SERVICE]: [],
            [FilterTypes.LOCATION]: [],
            [FilterTypes.STAFF_MEMBER]: [],
          },
      selectedVariantsOptions: [],
    });

    return refetchPlatformData(TriggeredByOptions.FILTER_CHANGED);
  };
}
</onfilterchanged></calendarstate,></void></record<filtertypes,>