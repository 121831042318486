import { CalendarState } from '../../components/BookingCalendar/controller';
import { CalendarContext } from '../context/contextFactory';
import {
  formatLocalDateTimeToDay,
  formatLocalDateTimeToShortWeekday,
  formatLocalDateTimeToWeekday,
  formatMonth,
  getDateTimeFromLocalDateTime,
  getStartOfNextDateLocalDateTime,
  getTodayLocalDateTimeStartOfDay,
} from '../dateAndTime/dateAndTime';
import { isWeeklyTimeSlotsLayout } from '../layouts';

export interface SlotsPerDay<slots> {
  date: string;
  weekday: string;
  day: string;
  isToday: boolean;
  isPastDate: boolean;
  dayWithoutSlots: string;
  slots: Slots;
  accessibility: {
    dayWithoutSlotsSrOnlyText: string;
    dayWithSlotsSrOnlyText: string;
    dayDetailsAriaLabel: string;
  };
}

export type SlotsFactory<slots> = (
  date: String,
  state: CalendarState,
  context: CalendarContext,
) => Slots;

export const getSlotsPerDaysInSelectedRange = <slots>({
  slotsFactory,
  state,
  context,
}: {
  slotsFactory: SlotsFactory<slots>;
  state: CalendarState;
  context: CalendarContext;
}): SlotsPerDay<slots>[] => {
  const { selectedRange, selectedTimezone } = state;
  const { businessInfo, t, settings, settingsParams, getContent } = context;
  const { from, to } = selectedRange!;
  const locale = businessInfo!.dateRegionalSettingsLocale!;
  const today = getToday(selectedTimezone!);
  const isWeeklyTimeSlots = isWeeklyTimeSlotsLayout(settings, settingsParams);
  const dayWithoutSlots = isWeeklyTimeSlots
    ? '-'
    : getContent({
        settingsParam: settingsParams.noSessionsOffered,
        translationKey: 'app.settings.defaults.slots.no-availability',
      });

  const slotsDetailsPerDay = [];
  let dateAsLocalDate = from;
  let date = getDateTimeFromLocalDateTime(from);
  do {
    const day = formatLocalDateTimeToDay(dateAsLocalDate, locale);
    const month = formatMonth(dateAsLocalDate, locale);

    const dayWithoutSlotsSrOnlyText = t(
      'app.week-availability.accessibility.day-without-slots',
      { month, day },
    );
    const dayWithSlotsSrOnlyText = t(
      'app.week-availability.accessibility.day-with-slots',
      { month, day },
    );
    const dayDetailsAriaLabel = t('app.week-availability.accessibility.day', {
      weekday: formatLocalDateTimeToWeekday(dateAsLocalDate, locale),
      day,
    });

    const slots = slotsFactory(dateAsLocalDate, state, context);

    slotsDetailsPerDay.push({
      date: dateAsLocalDate,
      slots,
      weekday: formatLocalDateTimeToShortWeekday(dateAsLocalDate, locale),
      day,
      isToday: date.getTime() === today.getTime(),
      isPastDate: date < today,
      dayWithoutSlots,
      accessibility: {
        dayWithoutSlotsSrOnlyText,
        dayWithSlotsSrOnlyText,
        dayDetailsAriaLabel,
      },
    });

    dateAsLocalDate = getStartOfNextDateLocalDateTime(dateAsLocalDate);
    date = getDateTimeFromLocalDateTime(dateAsLocalDate);
  } while (date <= getDateTimeFromLocalDateTime(to));

  return slotsDetailsPerDay;
};

export const getToday = (selectedTimezone: string) => {
  const today = getTodayLocalDateTimeStartOfDay(selectedTimezone);
  return getDateTimeFromLocalDateTime(today);
};
</slots></slots></slots></slots></slots>